@import "../../styles/base";

.specialOffersContainer {
  padding: 0 3rem 3rem;
  display: none; // Override with global css body class

  @include untilTablet {
    padding: 2rem 0 0 0;
  }
}

.specialOffersLink {
  display: block;
}

.specialOffers {
  background: #00273A url('../../images/special-offer--background.png') no-repeat center center;
  -webkit-box-shadow: inset 0 0 36px 10px rgba(0,0,0,.5);
  box-shadow: inset 0 0 36px 10px rgba(0,0,0,.5);
  border-radius: 16px;
  position: relative;
  color: #fff;
  border: none;
  background-size: cover;
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 18px 10px;
  padding-left: 7rem;

  @media only screen and (min-width: 768px) and (max-width: 1030px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 620px) {
    flex-direction: column;
  }

  &::before {
    content: 'Black Friday';
    text-shadow: 0 0 4px #42a5f5, 0 0 4px #42a5f5, 0 0 4px #42a5f5, 0 0 4px #42a5f5;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: .8px;
    text-transform: uppercase;
    display: block;
    padding-right: 10px;
  }

  &::after {
    content: '';
    height: 48px;
    width: 48px;
    display: block;
    position: absolute;
    background: url('../../images/special-offer--bolt.png') no-repeat 0 0;
    left: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: cover;
  }
}

.offerDescription {
  display: inline-block;
  text-decoration: underline;
  text-underline-offset: 4px;
  margin-top: -1px;
  padding-right: 5px;
}

.offerCode {
  margin-top: -1px;
  color: #9EFF00;
  text-decoration:underline;
  text-decoration-style: dotted;
  text-underline-offset: 4px;
  font-weight: bold;
}
